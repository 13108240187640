import React from 'react';
import './App.css';
import { FluentProvider, Tab, TabList, webLightTheme} from "@fluentui/react-components";
import { HistoryRegular, HistoryFilled, ListFilled, ListRegular, StarFilled, StarRegular } from "@fluentui/react-icons";
import History from "./pages/History";
import Study from "./pages/Study";

function App() {
  const [selected, setSelected] = React.useState('list');
  return (
    <FluentProvider theme={webLightTheme}>
      <TabList defaultSelectedValue={selected} appearance="subtle" onTabSelect={(_, data) => setSelected(data.value as string)}>
          <Tab value="history" icon={selected === 'history' ? <HistoryFilled /> : <HistoryRegular />}>历史记录</Tab>
          <Tab value="list" icon={selected === 'list' ? <ListFilled /> : <ListRegular />}>课程列表</Tab>
          <Tab value="favorite" icon={selected === 'favorite' ? <StarFilled /> : <StarRegular />}>收藏</Tab>
      </TabList>
      {selected === 'history' && <History />}
      {selected === 'list' && <Study />}
    </FluentProvider>
  );
}

export default App;
