import { useState } from 'react';
import { Alert, Button, Col, message, Row } from 'antd';
import Grid from 'antd/lib/card/Grid';
import './index.css';
import { useEffect } from 'react';

export enum EventType {
  NextQuestion,
  PrevQuestion,
  QuestionChagne,
}

export type Props = {
  question: data.List;
  isFirst: boolean;
  isLast: boolean;
  isLearningMode: boolean;
  on: (event: EventType) => void;
};

export default function QuestionView({ question, on, isFirst, isLast, isLearningMode }: Props) {
  const [answer, setAnswer] = useState<string[]>([]);
  const [showAnswer, setShowAnswer] = useState(isLearningMode);
  const [showAnalysis, setShowAnalysis] = useState(false);
  const v = question;

  useEffect(() => {
    setAnswer([]);
    setShowAnswer(isLearningMode);
    if (isLearningMode) {
      on(EventType.QuestionChagne);
    }
  }, [isLearningMode, on, question]);

  const judgeAnswer = () => {
    if (answer.length === 0) {
      message.warning('请先作答！');
      return;
    }
    const answerStr = answer.join(',');
    if (answerStr === v.rightkey) {
      message.success('回答正确!');
    } else {
      message.error('回答错误！');
    }
    setShowAnswer(true);
  };
  return (
    <div>
      <Row align="stretch" justify="space-between" className="header">
        <Col>
          <Button disabled={isFirst} type="primary" onClick={() => on(EventType.PrevQuestion)}>上一题</Button>
        </Col>
        <Col>
          <Button type="default" onClick={judgeAnswer} >判题</Button>
        </Col>
        <Col>
          <Button disabled={isLast} type="primary" onClick={() => on(EventType.NextQuestion)}>下一题</Button>
        </Col>
      </Row>
      <div className="question" dangerouslySetInnerHTML={{ __html: v.question }} />
      {
        isLearningMode && v.analysis && (
          <>
            <Row align="stretch" justify="end" className="question">
              <Button type="default" onClick={() => setShowAnalysis(!showAnalysis)}>{!showAnalysis ? '显示解析' : '隐藏解析'}</Button>
            </Row>
            <Row align="stretch" justify="space-between" hidden={!showAnalysis} className="question">
              <Alert type="success" message={<div dangerouslySetInnerHTML={{ __html: v.analysis }} />} />
            </Row>
          </>
        )
      }
      <div>
        {
          (typeof v.option === 'boolean'? [{
            o: 'A',
            p: '正确'
          },
            {
              o: 'B',
              p: '错误'
            }] : v.option)?.map(o => (
            <Grid className={`option ${showAnswer ? v.rightkey.split(',').includes(o.o) ? "success" : answer.includes(o.o) && "wrong" : ''} ${answer.includes(o.o) ? "selected" : ''}`}>
              <Row onClick={() => {
                if (v.type === '1') {
                  setAnswer(answer.includes(o.o) ? [] : [o.o]);
                  return;
                }
                if (answer.includes(o.o)) {
                  setAnswer(answer.filter(a => a !== o.o));
                } else {
                  setAnswer(answer.concat(o.o).sort(undefined));
                }
              }}>
                <Col>{o.o} - </Col>
                <Col dangerouslySetInnerHTML={{ __html: o.p }} />
              </Row>
            </Grid>
          ))
        }
      </div>
    </div >
  );
}
