import { useEffect, useState } from 'react';
import { Button, Card, Empty, Input, List, Row, Switch } from 'antd';
import QuestionView, { EventType } from './components/question-view';
import Grid from 'antd/lib/card/Grid';
import examData from "../../exam-data";

type LearnHistory = { exam: string; index: number; };

type PageState = {
  selectedExam: string | null;
  selectedQuestionIndex: number;
  files: string[];
  fileContent: data.RootObject | null;
  learningMode: boolean;
  learnHistories: LearnHistory[];
};
const initialState: PageState = {
  selectedExam: null,
  selectedQuestionIndex: 0,
  files: [],
  fileContent: null,
  learningMode: false,
  learnHistories: []
};
export default function Page() {
  const [pageState, setPageState] = useState<PageState>(initialState);
  const {
    selectedExam,
    selectedQuestionIndex,
    files,
    fileContent,
    learningMode,
    learnHistories
  } = pageState;

  const setSelectedQuestionIndex = (v: number) => setPageState((prev) => ({ ...prev, selectedQuestionIndex: v }));

  const getHistory = () => JSON.parse(localStorage.getItem('learn_history') || '[]') as LearnHistory[];

  useEffect(() => {
    console.log('files', pageState.files);
  }, [pageState]);

  useEffect(() => {
    setPageState((prev) => {
      const newState = ({
        ...prev,
        files: Object.keys(examData),
        learnHistories: getHistory().reverse().filter((v, index) => index < 3),
      })
      console.log('newState', newState);
        return newState;
    });
  }, []);

  useEffect(() => {
    if (selectedExam) {
      console.log('selectedExam', selectedExam);
      // 选择题库后加载文件
      setPageState((prevState => ({
        ...prevState,
        fileContent: examData[selectedExam],
        learnHistories: getHistory().reverse().filter((v, index) => index < 3),
      })));
    } else {
      // 返回列表后加载更新学习历史
      setPageState((prevState => ({
        ...prevState,
        learnHistories: getHistory().reverse().filter((v, index) => index < 3),
      })));
    }
  }, [selectedExam]);

  return (
    selectedExam
      ? <Card
        loading={!fileContent?.data.list}
        title={`${selectedExam} 第${selectedQuestionIndex + 1}题 共${fileContent?.data.total_qnum}题`}
        extra={<Row wrap={false}>
          <Switch
            checkedChildren="学习"
            unCheckedChildren="学习"
            onChange={checked => setPageState({ ...pageState, learningMode: checked })}
            size="default"
            style={{ width: 110, marginBlock: 'auto', marginInline: 8 }}
            checked={learningMode}
          />
          <Input.Search
            placeholder="序号"
            enterButton="跳转"
            size="middle"
            type="number"
            max={fileContent?.data.total_qnum}
            min={1}
            onSearch={(index) => setPageState({ ...pageState, selectedQuestionIndex: Number(index) - 1 })}
          />
          <Button type="primary" onClick={() => setPageState({ ...pageState, selectedExam: null })}>返回列表</Button>
        </Row>}>
        {
          fileContent
            && fileContent.data.list.length > selectedQuestionIndex
            ? <QuestionView
              isLearningMode={learningMode}
              question={fileContent.data.list[selectedQuestionIndex]}
              isFirst={selectedQuestionIndex === 0}
              isLast={selectedQuestionIndex === fileContent.data.list.length - 1}
              on={ev => {
                switch (ev) {
                  case EventType.PrevQuestion:
                    if (selectedQuestionIndex !== 0) {
                      setSelectedQuestionIndex(selectedQuestionIndex - 1);
                    }
                    break;
                  case EventType.NextQuestion:
                    if (selectedQuestionIndex < fileContent.data.list.length - 1) {
                      setSelectedQuestionIndex(selectedQuestionIndex + 1);
                    }
                    break;
                  case EventType.QuestionChagne:
                    let history: LearnHistory[] = JSON.parse(localStorage.getItem('learn_history') || '[]');
                    history = history.filter(h => h.exam !== selectedExam);
                    history.push({ exam: selectedExam, index: selectedQuestionIndex });
                    localStorage.setItem('learn_history', JSON.stringify(history));
                }
              }} />
            : <Empty />
        }
      </Card>
      : <>
        <Card title="上次学到">
          {
            learnHistories.map(({ exam, index }) => <Grid style={{
              width: '100%',
              margin: '8px',
              padding: '8px',
              cursor: 'pointer',
            }}>
              <div onClick={() => {
                setPageState({
                  ...pageState,
                  selectedExam: exam,
                  learningMode: true,
                  selectedQuestionIndex: index
                });
              }}>
                {exam} - 第{index + 1}题
              </div>
            </Grid>)
          }
        </Card>
        <Card title="题库列表">
          <List<string>
            size="large"
            itemLayout="vertical"
            bordered={false}
            renderItem={(item) => {
              return <List.Item style={{ border: 'none', width: '100%' }} onClick={() => {
                setPageState({
                  ...pageState,
                  selectedExam: item
                });
                // setSelectedExam(item);
              }}>
                <Grid style={{
                  width: '100%',
                  margin: '8px',
                  padding: '8px',
                  cursor: 'pointer',
                }}>
                  <span>
                    {item}
                  </span>
                </Grid>
              </List.Item>;
            }}
            dataSource={files}
          />
        </Card>
      </>
  );
}
