const examData: { [key:string]: data.RootObject } = {
	"ACA": (await import("./json-files/ACA.json")).default,
	"CLF-C02-CLF-C02-中文版-CLF-252题": (await import("./json-files/CLF-C02-CLF-C02-中文版-CLF-252题.json")).default,
	"CLF-C02-CLF-C02-中英文版-CLF-252题": (await import("./json-files/CLF-C02-CLF-C02-中英文版-CLF-252题.json")).default,
	"CLF-C02-CLF-C02-英文版-CLF-252题": (await import("./json-files/CLF-C02-CLF-C02-英文版-CLF-252题.json")).default,
	"CLF-C02-学习版-中英文1621题-1-300Q": (await import("./json-files/CLF-C02-学习版-中英文1621题-1-300Q.json")).default,
	"CLF-C02-学习版-中英文1621题-1201-1500Q": (await import("./json-files/CLF-C02-学习版-中英文1621题-1201-1500Q.json")).default,
	"CLF-C02-学习版-中英文1621题-1501-1621Q": (await import("./json-files/CLF-C02-学习版-中英文1621题-1501-1621Q.json")).default,
	"CLF-C02-学习版-中英文1621题-301-600Q": (await import("./json-files/CLF-C02-学习版-中英文1621题-301-600Q.json")).default,
	"CLF-C02-学习版-中英文1621题-601-900Q": (await import("./json-files/CLF-C02-学习版-中英文1621题-601-900Q.json")).default,
	"CLF-C02-学习版-中英文1621题-901-1200Q": (await import("./json-files/CLF-C02-学习版-中英文1621题-901-1200Q.json")).default,
	"CLF-C02-学习版-英文1621题-1-300Q": (await import("./json-files/CLF-C02-学习版-英文1621题-1-300Q.json")).default,
	"CLF-C02-学习版-英文1621题-1201-1500Q": (await import("./json-files/CLF-C02-学习版-英文1621题-1201-1500Q.json")).default,
	"CLF-C02-学习版-英文1621题-1501-1621Q": (await import("./json-files/CLF-C02-学习版-英文1621题-1501-1621Q.json")).default,
	"CLF-C02-学习版-英文1621题-301-600Q": (await import("./json-files/CLF-C02-学习版-英文1621题-301-600Q.json")).default,
	"CLF-C02-学习版-英文1621题-601-900Q": (await import("./json-files/CLF-C02-学习版-英文1621题-601-900Q.json")).default,
	"CLF-C02-学习版-英文1621题-901-1200Q": (await import("./json-files/CLF-C02-学习版-英文1621题-901-1200Q.json")).default,
	"SAA-C03-SAA-C03中文620题-1~327题-不用背": (await import("./json-files/SAA-C03-SAA-C03中文620题-1~327题-不用背.json")).default,
	"SAA-C03-SAA-C03中文620题-328~620题-精简": (await import("./json-files/SAA-C03-SAA-C03中文620题-328~620题-精简.json")).default,
	"SAA-C03-SAA-C03中英文620题-1~327题-不用背": (await import("./json-files/SAA-C03-SAA-C03中英文620题-1~327题-不用背.json")).default,
	"SAA-C03-SAA-C03中英文620题-328~620题-精简": (await import("./json-files/SAA-C03-SAA-C03中英文620题-328~620题-精简.json")).default,
	"SAA-C03-SAA-C03英文620题-1~327题-不用背": (await import("./json-files/SAA-C03-SAA-C03英文620题-1~327题-不用背.json")).default,
	"SAA-C03-SAA-C03英文620题-328~620题-精简": (await import("./json-files/SAA-C03-SAA-C03英文620题-328~620题-精简.json")).default,
	"SAP-C02-SAP-C02精简中文-360题-中文": (await import("./json-files/SAP-C02-SAP-C02精简中文-360题-中文.json")).default,
	"SAP-C02-SAP-C02精简中英文-360题-中英文": (await import("./json-files/SAP-C02-SAP-C02精简中英文-360题-中英文.json")).default,
	"SAP-C02-SAP-C02精简英文-360题-英文": (await import("./json-files/SAP-C02-SAP-C02精简英文-360题-英文.json")).default,
	"SAP-C02-SAP-C02精简英文-新题-109题": (await import("./json-files/SAP-C02-SAP-C02精简英文-新题-109题.json")).default
};
export default examData;